export const ROLE_ADMIN = 'admin'
export const ROLE_EDITOR = 'editor'

const COOKIE_USERNAME = 'ukeJukeboxUsername'
const COOKIE_ROLES = 'ukeJukeboxRoles'

export const getUsername = () => {
  const username = getCookieValue(COOKIE_USERNAME)
  if (username && username.length > 0) {
    return username
  } else {
    return 'not logged in!'
  }
}

export const checkRole = (role) => {
  const roles = getCookieValue(COOKIE_ROLES).split(',')
  return roles.includes(role)
}

export const checkRoles = (roles) => {
  if (!roles || roles.length === 0) {
    return false
  }
  let allowed = false
  roles.forEach((role) => {
    if (checkRole(role)) {
      allowed = true
    }
  })
  return allowed
}

export const getCookieValue = (cname) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

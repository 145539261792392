import React from 'react'
import './layout.css'
import { useCMS, useScreenPlugin, MediaManager } from 'tinacms'
import {
  UsernameDisplayWidget,
  LogoutWidget,
  StartPageWidget,
  DeployWidget,
} from '../cms/toolbarWidgets'
import { StationsPlugin } from '../cms/stationsPlugins'
import { ReferencesPlugin } from '../cms/referencesPlugins'
import { BlogsPlugin } from '../cms/blogsPlugins'
import { LandingpagesPlugin } from '../cms/landingpagesPlugins'
import {
  StationCreatorPlugin,
  ReferenceModuleCreatorPlugin,
  BlogCreatorPlugin,
  LandingpageCreatorPlugin,
} from '../cms/contentCreatorPlugins'
import { FilesystemMediaStore } from '../cms/mediaStore'
import { MarkdownFieldPlugin, HtmlFieldPlugin } from 'react-tinacms-editor'
import { checkRole, checkRoles, ROLE_ADMIN, ROLE_EDITOR } from '../cms/security'
import { ClustersPlugin } from '../cms/clustersPlugins'

export function CMSWrapper({ children }) {
  if (process.env.NODE_ENV !== 'production') {
    return <CMSPlugins>{children}</CMSPlugins>
  } else {
    return children
  }
}

function CMSPlugins({ children }) {
  const cms = useCMS()

  cms.events.subscribe('*', (event) => {
    console.debug(event.type)
  })
  cms.media = new MediaManager(new FilesystemMediaStore(), cms.events)
  useScreenPlugin(StationsPlugin)
  useScreenPlugin(ReferencesPlugin)
  useScreenPlugin(BlogsPlugin)
  useScreenPlugin(LandingpagesPlugin)
  useScreenPlugin(ClustersPlugin)

  React.useEffect(() => {
    if (checkRole(ROLE_ADMIN)) {
      cms.plugins.add(StationCreatorPlugin)
      cms.plugins.add(ReferenceModuleCreatorPlugin)
    }
    if (checkRoles([ROLE_ADMIN, ROLE_EDITOR])) {
      cms.plugins.add(BlogCreatorPlugin)
      cms.plugins.add(LandingpageCreatorPlugin)
    }
    cms.plugins.add(MarkdownFieldPlugin)
    cms.plugins.add(HtmlFieldPlugin)
    cms.plugins.add(UsernameDisplayWidget)
    cms.plugins.add(LogoutWidget)
    cms.plugins.add(StartPageWidget)
    if (checkRoles([ROLE_ADMIN])) {
      cms.plugins.add(DeployWidget)
    }

    return () => {
      if (StationCreatorPlugin) {
        cms.plugins.remove(StationCreatorPlugin)
      }
      if (ReferenceModuleCreatorPlugin) {
        cms.plugins.remove(ReferenceModuleCreatorPlugin)
      }
      if (BlogCreatorPlugin) {
        cms.plugins.remove(BlogCreatorPlugin)
        cms.plugins.remove(LandingpageCreatorPlugin)
      }
      cms.plugins.remove(MarkdownFieldPlugin)
      cms.plugins.remove(HtmlFieldPlugin)
      cms.plugins.remove(LogoutWidget)
      cms.plugins.remove(StartPageWidget)
      if (DeployWidget) {
        cms.plugins.remove(DeployWidget)
      }
    }
  }, [cms.plugins])
  return <>{children}</>
}
